
    .discuss-content {
        height: 100%;
        display: flex;
        flex-direction: column;
        .discuss-details {
            height: calc(100% - 220px);
            ::v-deep .el-scrollbar__wrap {
                margin-right: -16px;
                overflow-x: hidden;
            }
        }
        .discuss-list {
            /*height: 100%;*/
            margin-top: 0;
            padding: 0 28px;
            list-style: none;
            margin-bottom: 0;
            li {
                margin-bottom: 10px;
                .user {
                    display: flex;
                    justify-content: start;
                    align-items: center;
                    margin: 10px 0;
                    .user-avatar {
                        width: 24px;
                        height: 24px;
                        border-radius: 50%;
                        margin-right: 10px;
                    }
                    .teacher-sign {
                        background-color: #3295F9;
                        color: #fff;
                        width: 40px;
                        text-align: center;
                        margin-right: 10px;
                    }
                    .teacher-name {
                        color: #3295F9;
                        margin-right: 10px;
                        max-width: 190px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                    .teacher-time {
                        color: #b7b4b4;
                        font-size: 12px;
                    }
                }
                .content {
                    margin: 8px 0;
                    display: block;
                    ::v-deep img {
                        max-width: 100%;
                    }
                }
                .message-enter {
                    display: flex;
                    flex-direction: column;
                    .time {
                        text-align: center;
                        color: #b7b4b4;
                        font-size: 12px;
                    }
                    .message {
                        display: block;
                        height: 30px;
                        line-height: 30px;
                        text-align: center;
                        background-color: #f9f8f8;
                        color: #949191;
                        margin-top: 10px;
                    }
                }
            }
        }
        .discuss-send {
            height: 220px;
            ::v-deep .ke-content {
                padding: 10px;
            }
            ::v-deep .ke-container {
                border-bottom: none;
                .ke-statusbar {
                    display: none;
                }
            }
            #editor-discuss {
                height: calc(100% - 70px);
                ::v-deep .w-e-text-container {
                    border: none !important;
                    height: 120px !important;
                    .w-e-text {
                        box-sizing: initial;
                    }
                    .w-e-panel-tab-content {
                        height: 142px !important;
                    }
                }
            }
            button {
                margin: 15px;
                float: right;
            }
        }
    }
