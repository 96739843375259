
    .ep-tmpl {
        width: 100%;
        height: 100%;
        background-color: #FFF;
        .paper-wrapper {
            ::v-deep .el-scrollbar__wrap {
                overflow-x: hidden;
            }
        }
        .paper-container {
            background-color: #FFF;
            width: 100%;
            .paper-title {
                width: 100%;
                text-align: center;
                font-size: 24px;
                color: #409eff;
                font-weight: 600;
            }
            .paper-content {
                margin-top: 20px;
                padding: 0 20px;
            }
        }
    }
    .class-wrapper {
        height: 100%;
        display: flex;
        flex-direction: column;
        .class-header {
            background: #fff;
            height: 60px;
            line-height: 60px;
            text-align: center;
            font-size: 16px;
            position: relative;
            box-shadow: 0 1px 5px 0 rgba(0, 0, 0, .2);
            /*z-index: 20000;*/
        }
        .class-container {
            height: 1%;
            flex: 1;
            display: flex;
            .nav {
                width: 60px;
                color: #B3B3B3;
                background: #1C1F21;
                display: flex;
                justify-content: center;
                flex-direction: column;
                .nav-item {
                    cursor: pointer;
                    width: 60px;
                    height: 60px;
                    text-align: center;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    .iconfont {
                        font-size: 16px;
                    }
                    .text {
                        margin-top: 2px;
                    }
                    &.current {
                        color: #fff;
                        background: rgb(38, 41, 44);
                    }
                }
            }
            .container {
                width: 1%;
                flex: 1;
                overflow: hidden;
                background-color: #FFF;
                .video-box {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: #000;
                    video {
                        max-width: 100%;
                        max-height: 100%;
                        outline: none;
                    }
                }
                .iframe-content {
                    height: 100%;
                }
            }
        }
    }
    .class-header {
        & > span {
            margin-right: 10px;
            color: #1989FA;
        }
        .timer {
            color: #FF0000;
            margin: 0 50px 0 20px;
            span {
                color: #FF0000;
                margin-left: 20px;
            }
        }
        .user {
            position: absolute;
            top: 50%;
            right: 40px;
            transform: translateY(-50%);
            .user-info {
                display: flex;
                align-items: center;
                .user-name {
                    font-size: 16px;
                    color: #606266;
                    margin-right: 20px;
                }
                .el-icon-arrow-down {
                    color: #606266;
                }
            }
            .user-avatar {
                width: 30px;
                height: 30px;
                border-radius: 50%;
                overflow: hidden;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                -webkit-box-pack: center;
                -ms-flex-pack: center;
                justify-content: center;
                border: 1px solid #ddd;
                img {
                    max-width: 100%;
                    max-height: 100%;
                }
            }
            .header-el-dropdown {
                display: flex;
                align-items: center;
                cursor: pointer;
                padding: 10px 0;
                .user-text {
                    line-height: 40px;
                    margin-left: 10px;
                }
                img {
                    display: block;
                    width: 36px;
                    height: 36px;
                    border-radius: 50%;
                }
            }
        }
    }
    .chapter-drawer {
        position: fixed;
        left: 60px;
        top: 60px;
        ::v-deep .el-drawer {
            background: #26292C;
            outline: none;
        }
        ::v-deep .el-drawer__body {
            outline: none;
            height: 100%;
        }
    }
    .practical-drawer {
        position: fixed;
        top: 60px;
        left: 60px;
        ::v-deep .el-drawer {
            height: 100%;
            border-top: 1px solid #EAEAEA;
            padding: 15px 0 0;
            &:focus {
                outline: none;
            }
            .el-drawer__body {
                height: 100%;
                .practical-content {
                    height: 100%;
                }
                .el-tabs {
                    display: flex;
                    flex-direction: column;
                    height: 100%;
                    .el-tabs__nav {
                        padding: 0 28px;
                        .el-tabs__active-bar {
                            left: 28px;
                        }
                    }
                    .el-tabs__content {
                        flex: 1;
                    }
                }
            }
        }
    }
    .discuss-drawer {
        position: fixed;
        top: 60px;
        left: 60px;
        ::v-deep .el-drawer {
            height: 100%;
            border-top: 1px solid #EAEAEA;
            outline: none;
            &:focus {
                outline: none;
            }
            .el-drawer__header span {
                outline: none;
            }
            .el-drawer__body {
                height: calc(100% - 76px);
                .practical-content {
                    height: 100%;
                }
            }
        }
    }
    .quiz-drawer {
        position: fixed;
        top: 60px;
        left: 60px;
        ::v-deep .el-drawer {
            border-top: 1px solid #EAEAEA;
            outline: none;
            background: #F5F6FA;
        }
        ::v-deep .el-drawer__header {
            margin-bottom: 0;
            span, button {
                outline: none;
            }
        }
        ::v-deep .el-drawer__body {
            outline: none;
            height: calc(100% - 45px);
        }
    }
    .signDialog {
        ::v-deep .el-dialog__body {
            border-top: 1px solid #EAEAEA;
            border-bottom: 1px solid #EAEAEA;
            padding: 20px 0;
        }
        ::v-deep .el-dialog__footer {
            text-align: center;
        }
        .sign-content {
            text-align: center;
            font-size: 16px;
            .sign-title {
                margin-bottom: 10px;
            }
        }
    }
    .blackboard-dialog {
        ::v-deep .el-dialog__body {
            height: calc( 100% - 60px);
            padding: 0 10px;
        }
    }
    .chapterNode-cascader-wrapper {
        position: relative;
        padding: 10px 10px 0;
        z-index: 9999;
        .el-input {
            ::v-deep .el-input__inner {
                background-color: transparent;
                color: #F5F7FA;
            }
        }
        .box {
            position: absolute;
            left: 10px;
            top: 50px;
            right: 10px;
            .chapterNode-cascaderBox {
                height: 200px;
                box-sizing: border-box;
                background-color: #fff;
                ::v-deep .el-cascader-menu__wrap {
                    height: 100%;
                    overflow-x: hidden;
                }
                ::v-deep .el-radio {
                    &.is-disabled {
                        & + .el-cascader-node__label {
                            color: #c0c4cc;
                        }
                        & + .el-cascader-node__label + .el-icon-arrow-right {
                            color: #c0c4cc;
                        }
                    }
                }
            }
        }
    }

