
    .quiz-wrapper {
        height: 100%;
        display: flex;
        flex-direction: column;
        .quiz-content {
            height: 100%;
            flex: 1;
            ::v-deep .el-scrollbar__wrap {
                overflow-x: hidden;
            }
            .el-timeline {
                padding: 20px;
                .quiz-item {
                    &:last-child {
                        padding-bottom: 0;
                    }
                    ::v-deep .el-timeline-item__tail {
                        border-color: #E10303;
                    }
                    .el-card {
                        margin-bottom: 16px;
                        &:last-child {
                            margin-bottom: 0;
                        }
                        ::v-deep .el-card__body {
                            padding: 16px;
                        }
                    }
                    .item-info {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        .user-avatar {
                            width: 24px;
                            height: 24px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            overflow: hidden;
                            border-radius: 50%;
                            border: 1px solid #ddd;
                            img {
                                max-width: 100%;
                                max-height: 100%;
                            }
                        }
                        .user-name {
                            width: 1%;
                            flex: 1;
                            margin: 0 10px;
                        }
                    }
                    .item-bottom {
                        margin-top: 10px;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        .item-title {
                            width: 1%;
                            flex: 1;
                        }
                        .item-time {
                            color: #999;
                        }
                        .item-status {
                            margin: 0 10px;
                            .red {
                                color: #ff0000;
                            }
                            .blue {
                                color: #409eff;
                            }
                        }
                    }
                }
            }
        }
        .choose-paper {
            padding: 10px 20px;
            border-top: 1px solid #ddd;
            background: #fff;
            text-align: center;
        }
    }
    .publish-project-form {
        .select-project {
            ::v-deep .el-form-item__content {
                margin-left: 73px !important;
                .el-select {
                    width: 78% !important;
                }
            }
        }
    }

