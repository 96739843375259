
    ::v-deep .el-scrollbar__view {
        padding-right: 20px;
        padding-left: 20px;
    }

    ::v-deep .el-scrollbar__wrap {
        overflow-x: hidden;
    }

    ::v-deep .el-carousel__indicators--outside {
        display: none;
    }

    .main-box {
        height: calc(100% - 120px);
        width: 93%;
        margin: 20px auto;
        background: #ffffff;

        .main-content {
            background: #ffffff;
            height: 100%;

            .subject-content {
                flex: 1;
                height: calc(100% - 63px);

                ::v-deep .el-scrollbar__view {
                    padding: 20px 100px 0 100px;
                }

                .head-box {
                    .title-box {
                        display: flex;
                    }

                    .head-content {
                        padding-top: 60px;
                        display: flex;

                        .img-box {
                            width: 200px;
                            height: 200px;

                            img {
                                max-height: 100%;
                                max-width: 100%;
                            }
                        }

                        .right-content {
                            margin-left: 40px;
                            overflow: hidden;
                            height: 200px;
                            width: calc(100% - 240px);

                            .row1 {
                                display: flex;
                                justify-content: space-between;

                                .left-box {
                                    display: flex;

                                    .title-name {
                                        font-weight: bold;
                                    }

                                    .titile-detail {
                                        margin-left: 10px;
                                    }
                                }

                                .right-box {
                                    display: flex;

                                    .time-detail {
                                        margin-left: 10px;
                                    }

                                    .titile-detail {
                                        margin-left: 10px;
                                    }
                                }
                            }

                            .row2 {
                                display: flex;
                                margin-top: 40px;

                                .left-box {
                                    display: flex;

                                    .title-name {
                                        font-weight: bold;
                                        min-width: 65px;
                                    }

                                    .titile-detail {
                                        margin-left: 10px;
                                        word-break: break-all;
                                        line-height: 1.5;
                                        max-height: 120px;
                                    }
                                }
                            }

                        }

                    }
                }

                .box-content {
                    display: flex;
                    margin-top: 100px;

                    .card {
                        width: calc(25% - 20px);
                        min-width: 250px;
                        height: 518px;
                        background: #FFFFFF;
                        box-shadow: 0px 1px 5px 0px rgba(32, 32, 32, 0.14);
                        border-radius: 10px;
                        position: relative;

                        .first-show {
                            height: 100%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            flex-direction: column;

                            .show-title {
                                font-weight: bold;
                                margin-top: 85px;
                            }
                        }

                        .card-content {
                            width: 80%;
                            position: absolute;
                            top: 100px;
                            left: 0;
                            opacity: 0;
                            display: flex;
                            flex-direction: column;
                            text-align: center;
                            padding: 0 40px;

                            .content-box {
                                margin-top: 37px;
                                text-align: left;
                                word-break: break-all;
                                line-height: 1.5;
                                max-height: 300px;
                                overflow: hidden;
                            }
                        }
                    }

                    .card:before {
                        content: "";
                        width: 0;
                        height: 100%;
                        background: #fff;
                        position: absolute;
                        top: 0;
                        left: 50%;
                        opacity: 0;
                    }

                    .card:hover:before {
                        width: 100%;
                        left: 0;
                        opacity: 0.5;
                    }

                    .card:hover {
                        box-shadow: 0px 5px 17px 1px rgba(32, 32, 32, 0.14);
                        border-radius: 10px;
                    }

                    .card:hover .card-content {
                        opacity: 1;
                    }

                    .card:hover .first-show {
                        opacity: 0;
                    }
                }

                .task-box {
                    margin-top: 60px;

                    .task-content {
                        width: 100%;
                        height: 622px;
                        border: 1px solid #DDDDDD;
                        border-radius: 10px;
                        margin-top: 20px;

                        .task-main {
                            display: flex;
                            height: 100%;

                            .left-task {
                                width: 50%;
                                padding: 76px 68px 40px 38px;
                                word-break: break-all;
                                line-height: 1.5;
                                overflow: hidden;
                                height: 80%;
                            }

                            .right-task {
                                width: 50%;
                                height: 100%;

                                .right-img {
                                    padding: 40px 40px 40px 0;
                                    width: 95%;
                                    height: 88%;

                                    img {
                                        max-height: 100%;
                                        max-width: 100%;
                                    }
                                }
                            }
                        }
                    }
                }

                .step-box {
                    margin-top: 60px;

                    .step-title {
                        font-weight: bold;
                    }

                    .table-box {
                        border: 1px solid #DDDDDD;
                        margin-top: 20px;
                    }
                }

                .ability-box {
                    margin-top: 60px;

                    .ability-title {
                        font-weight: bold;
                    }

                    .ability-content {
                        margin-top: 20px;
                        min-height: 100px;
                        background: #F5F7FA;
                        border-radius: 10px;

                        .ability-content-box {
                            padding: 20px;
                            word-break: break-all;
                            line-height: 1.5;
                        }
                    }
                }

                .knowledge-content {
                    display: flex;
                    flex-direction: column;
                    margin-top: 60px;

                    .knowledge-title {
                        font-weight: bold;
                    }

                    .btn {
                        margin-top: 20px;
                        width: 100px;
                        height: 30px;
                        background: #ECF5FF;
                        border: 1px solid #DCECFE;
                        border-radius: 4px;
                        color: #409EFF;
                        text-align: center;
                        line-height: 2;
                        cursor: pointer;
                    }

                    .show-box {
                        min-height: 100px;
                        background: #F5F7FA;
                        border-radius: 10px;
                        margin-top: 10px;
                        display: flex;

                        .knowledge-list-box {
                            width: 100%;
                            padding-top: 10px;
                            display: flex;
                            flex-wrap: wrap;

                            .knowledge-list-content {
                                position: relative;
                                width: calc(10% - 30px);
                                min-width: 100px;
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                                padding: 0 20px;

                                .circle-box {
                                    width: 60px;
                                    height: 60px;
                                    border-radius: 50%;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    color: #ffffff;

                                    ::v-deep .iconfont {
                                        font-size: 26px;
                                    }
                                }

                                .circle-bottom {
                                    height: 30px;
                                    overflow: hidden;
                                    margin-top: 10px;
                                    max-width: 100%;
                                    text-overflow: ellipsis;
                                    white-space: nowrap;
                                }

                                .del-btn {
                                    width: 18px;
                                    height: 18px;
                                    background: #FF0000;
                                    color: #ffffff;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    border-radius: 50%;
                                    position: absolute;
                                    right: 0;
                                    top: 20px;
                                    opacity: 0;
                                    cursor: pointer;
                                }
                            }

                            .knowledge-list-content:before {
                                content: "";
                                width: 0;
                                height: 100%;
                                position: absolute;
                                top: 0;
                                left: 50%;
                                opacity: 0;
                            }


                            .knowledge-list-content:hover:before {
                                width: 100%;
                                left: 0;
                                opacity: 0.5;
                            }

                            .knowledge-list-content:hover .del-btn {
                                opacity: 1;
                            }
                        }
                    }
                }

                .sucai-box {
                    margin-top: 60px;

                    .btn {
                        margin-top: 20px;
                        width: 100px;
                        height: 30px;
                        background: #ECF5FF;
                        border: 1px solid #DCECFE;
                        border-radius: 4px;
                        color: #409EFF;
                        text-align: center;
                        line-height: 2;
                        cursor: pointer;
                    }

                    .sucai-title {
                        font-weight: bold;
                    }

                    .sucai-content {
                        margin-top: 20px;
                        min-height: 70px;
                        background: #F5F7FA;
                        border-radius: 10px;
                        display: flex;

                        .sucai-content-box {
                            padding: 20px;
                            word-break: break-all;
                            line-height: 1.5;
                        }
                    }
                }
            }
        }

        .app-detail-dialog {
            ::v-deep .el-dialog {
                ::v-deep .el-dialog__header {
                    border-bottom: 1px solid #e6e7ea;
                }

                .title {
                    text-align: center;
                    margin: 0;
                }

                .content {
                    display: flex;
                    flex-direction: column;

                    .h2 {
                        font-size: 18px;
                        font-weight: 500;
                        text-align: center;
                        margin: 10px 0;
                    }

                    .soft-introduction {
                        text-align: center;
                    }

                    .soft-content {
                        padding: 10px 50px;
                        min-height: 200px;
                    }
                }

                ::v-deep .el-dialog__footer {
                    border-top: 1px solid #e6e7ea;
                    padding: 20px;

                    a {
                        padding: 7px 20px;
                        font-size: 14px;
                        border-radius: 4px;
                        margin-right: 20px;
                    }

                    a.home {
                        border: 1px solid #DCDFE6;
                        color: #606266;

                        &:hover {
                            color: #409EFF;
                            border-color: #c6e2ff;
                            background-color: #ecf5ff;
                        }
                    }

                    a.shop {
                        color: #FFF;
                        background-color: #409EFF;
                        border-color: #409EFF;

                        &:hover {
                            background: #66b1ff;
                            border-color: #66b1ff;
                            color: #FFF;
                        }
                    }
                }
            }
        }
    }
